import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useAuth, useData } from '~/store';
import { OptionDefault } from '~/types';
import { lang, usernameRegex } from '~/constants';
import { PageContent, PageTitle } from '~/containers';

import { MediaState, MediaStateArray } from '~/services/signup';

import {
	Account,
	handleError,
	ResponseFail,
	IBusinessInfo,
	isAthlete,
	isBusiness,
	MediaPhotoInput,
	MediaVideoInput
} from '~/services';

import {
	Button,
	Input,
	InputPhone,
	PhotoButton,
	ProfileDelete,
	ProfileUrlNotice,
	RSelect,
	VideoButton
} from '~/components';

type FileType = 'photo' | 'video';

type ProfileForm = {
	email: string,
	gender: OptionDefault,
	phone: string,
	username: string,
	first_name: string,
	last_name: string,
	intro_video: string,
	profile_photo: string,
	profile_description: string,
	business: IBusinessInfo
}

const usernameReg = new RegExp(`^${usernameRegex}$`, 'i');

export const Info: React.FC = () => {

	const { goBack } = useHistory();

	const { account, updateAccount } = useAuth();

	const { options, util } = useData();

	const [ deleteModal, setDeleteModal ] = useState(false);

	const { control, handleSubmit, setError, watch } = useForm<ProfileForm>({
		defaultValues: {
			email: account?.email,
			phone: account?.phone,
			username: account?.username === account?.id ? '' : account?.username,
			first_name: account?.first_name,
			last_name: account?.last_name,
			business: isBusiness(account) ? {
				name: account?.business_info.name,
				about: account?.business_info.about,
				category_list: account?.business_info.category_list,
				description: account?.business_info.description,
				phone: account?.business_info.phone,
				email: account?.business_info.email,
				website: account?.business_info.website,
				single_line_address: account?.business_info.single_line_address,
			} : undefined,
		},
	});

	const [ loading, setLoading ] = useState(false);

	const [ photo, setPhoto ] = useState<MediaStateArray>({
		file: account?.profile_photo,
	});

	const [ video, setVideo ] = useState<MediaState>({
		file: account?.intro_video,
	});

	const submit = async (form: ProfileForm) => {

		try {

			if (!account) {
				return;
			}

			setLoading(true);

			const upload = async (type: FileType) => {

				if (type === 'photo' && typeof photo.file === 'string') {
					return photo.file;
				}

				if (type === 'video' && typeof video.file === 'string') {
					return video.file;
				}

				const { path } = await Account.media[type](
					{
						[type]: type === 'photo' ? photo.file?.[0] : video.file,
						...(type === 'photo' && photo.file?.[1] ? { second_photo: photo.file[1] } : undefined),
					} as MediaPhotoInput & MediaVideoInput
				).promise;

				return path;

			}

			form.intro_video = await upload('video');
			form.profile_photo = await upload('photo');

			const input = {
				...form,
				gender: form.gender?.value || '',
				user_type: account.user_type,
				...(isAthlete(account) && {
					athlete_info: account.athlete_info,
				}),
				...(isBusiness(account) && {
					business_info: form.business,
				}),
			};

			await updateAccount(input);

			goBack();

		} catch (e) {

			setLoading(false);

			const { code, message } = e as ResponseFail;

			switch (code) {
				case 208:
					return void setError('email', { message });
				case 211:
					return void setError('username', { message });
				default:
					return void handleError(e);
			}

		}

	}

	const _username = watch('username');

	return (
		<>
			<PageTitle
				title="Edit your profile info"
				onBack={goBack} />
			<PageContent
				pageContentClassName="page--settings">
				<PhotoButton
					photo={photo.file}
					error={photo.error}
					onPhotoChange={(file) => setPhoto({ file })}
					isCrop
					isAthlete={isAthlete(account)} />
				{isBusiness(account) ? (
				<>
					<Input
						name="business.name"
						icon="user2"
						rules={{ required: true }}
						control={control}
						placeholder="Business name" />
					<Input
						name="business.about"
						icon="description"
						control={control}
						textarea
						placeholder="About" />
					<Input
						name="business.category_list"
						icon="description"
						control={control}
						textarea
						placeholder="Category" />
					<Input
						name="business.description"
						icon="description"
						control={control}
						textarea
						placeholder="Description" />
					<Input
						name="business.phone"
						icon="phone"
						rules={{ required: true }}
						control={control}
						placeholder="Phone" />
					<Input
						name="business.email"
						icon="mail"
						rules={{ required: true }}
						control={control}
						placeholder="Email" />
					<Input
						name="business.website"
						icon="link"
						rules={{ required: true }}
						control={control}
						placeholder="Website" />
					<Input
						name="business.single_line_address"
						icon="mapPin"
						control={control}
						textarea
						placeholder="Address" />
				</>
				) : (
				<>
					<Input
						name="email"
						icon="mail"
						rules={{ required: true }}
						control={control}
						placeholder="Email" />
					{isAthlete(account) &&
					<>
						<Input
							name="username"
							icon="link"
							rules={{
								required: account?.username !== account?.id,
								validate: (val) => account?.username !== account?.id &&
									!usernameReg.test(val) ? lang.USERNAME_INVALID : true,
							}}
							control={control}
							placeholder="Account link" />
						<ProfileUrlNotice
							username={_username}
							isValid={usernameReg.test(_username)} />
					</>
					}
					<InputPhone
						name="phone"
						icon="phone"
						control={control}
						rules={{
							required: true,
							validate: (val) => /^\+1(\d{10})$/.test(val) ?
								true :
								lang.INVALID_PHONE,
						}} />
					<Input
						name="first_name"
						icon="user2"
						rules={{ required: true }}
						control={control}
						placeholder="First name" />
					<Input
						name="last_name"
						icon={null}
						rules={{ required: true }}
						control={control}
						placeholder="Last name" />
				</>
				)}
				{!isBusiness(account) && !!options.genders.length &&
				<RSelect
					name="gender"
					icon="user"
					rules={{ required: true }}
					options={options.genders}
					control={control}
					placeholder="Gender"
					defaultValue={util.optionById('genders', account?.gender)} />
				}
				{isAthlete(account) && (
				<>
					<VideoButton
						video={video.file}
						error={video.error}
						onError={(error) => setVideo({ error })}
						onVideoChange={(file) => setVideo({ file })} />
				</>
				)}
				<Button
					label="Save Profile"
					variant="primary"
					loading={loading}
					onClick={handleSubmit(submit)} />
				<Button
					type="button"
					label="Delete Profile"
					onClick={setDeleteModal.bind(null, true)}
					variant="danger" />
			</PageContent>
			<ProfileDelete
				onHide={setDeleteModal.bind(null, false)}
				visible={deleteModal} />
		</>
	);

}
