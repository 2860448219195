import { Link, NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useAuth } from '~/store';
import { Button, Icon } from '~/components';

export const FrameManage: React.FC = (props) => {

	const { children } = props;

	const { loading, unAuthorize } = useAuth();

	return (
		<>
			<aside className="manage-aside">
				<Link to="/manage/users">
					<Icon name="logo" />
				</Link>
				<nav>
					<NavLink to="/manage/users">
						<Icon name="MenuProfile" />
						<span>Users</span>
					</NavLink>
					<NavLink to="/manage/stats">
						<Icon name="MenuDashboard" />
						<span>Statistics</span>
					</NavLink>
					<NavLink to="/manage/pushes">
						<Icon name="MenuRequests" />
						<span>Pushes</span>
					</NavLink>
				</nav>
				<div className="logout">
					<Button
						label="Logout"
						loading={loading.unAuthorize}
						onClick={() => unAuthorize()} />
				</div>
			</aside>
			<main children={children} />
			<ToastContainer
				theme="dark"
				position="bottom-right" />
		</>
	);

}
